import { Route, useNavigate, Routes } from "react-router-dom"

// nested routes
import Offers from "./Offers"

export default function About() {
  const navigate = useNavigate()
  return (
    <div className="content">
      <h2>올리브 필로소피는...</h2>
      <p>올리브 필로소피는 인공지능 일기 프로그램 유니버스를 만들고 운영하는 스타트업입니다. </p>

      {/* To programmatically redirect users  */}

      <button onClick={()=>navigate('/products')}>See our products</button>

      <Routes>
        <Route path="offers" element={<Offers />}/>
      </Routes>
    </div>
  )
}
