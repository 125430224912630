import { BrowserRouter, Link, Route, Routes, Navigate } from 'react-router-dom'

import { useState} from 'react';



// pages
import Home from './pages/Home'
import About from './pages/About'
import Products from './pages/Products'
import ProductDetails from './pages/ProductDetails'
import Universe from './pages/Universe';



function App() {
  const [cartIsEmpty, setCartIsEmpty] = useState(false)
  return (
      <div className="App">
        <BrowserRouter>
          <nav>
            <h1>Olive Philosophy</h1>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/universe">AI Journal - Universe</Link>
          </nav>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about/*" element={<About/>}/>
            <Route path="/products/:id/*" element ={<ProductDetails />}/>
            <Route path="/products" element ={<Products />} />
            <Route path="/universe" element ={<Universe />} />
            <Route path="/redirect" element={<Navigate to="/about" />} />
            <Route 
              path="/checkout"
              element={cartIsEmpty? <Navigate to="/products"/> : <p>check out</p>}
            />
          </Routes>
        </BrowserRouter>
      </div>
  )
}

export default App