import Universe from "../img/universe.png"

export default function Home() {
  return (
    <div className="content">
      <p>안녕하세요. 올리브 필로소피 공식 홈페이지에 오신 것을 환영합니다. </p>
      {/* <img src="https://via.placeholder.com/1200x200" alt="site banner" /> */}
      <img src={Universe} alt="product" />
      <p>올리브 필로소피는 인공지능 일기 프로그램 유니버스를 만들고 운영하는 스타트업입니다.</p>

  
    </div>
  )
}
